<template>
  <MainLayout>
    <div class="relative">
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">
          Validasi Pengisian KRS Mahasiswa
        </h2>
        <div
          class="w-full sm:w-auto md:flex justify-center items-center align-center mt-4 sm:mt-0"
        ></div>
      </div>

      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <div v-if="loading" class="block-loading">
            <LoadingIcon icon="three-dots" class="w-8 h-8" />
            <div class="text-center text-xs mt-2">
              Loading data... Please Wait...
            </div>
          </div>

          <div class="intro-y box">
            <div class="grid grid-cols-12 mb-3">
              <div class="col-span-12 xl:col-span-8 p-3">
                <div class="grid grid-cols-2 gap-3">
                  <div class="col-span-1">
                    <label class="form-label">Tahun Akademik</label>
                    <TailSelectSelector
                      :list-options="listOptionsState?.semester"
                      @cbox-select="selectSemester"
                    ></TailSelectSelector>
                  </div>
                  <div class="col-span-1">
                    <label class="form-label">Program Studi</label>
                    <TailSelectSelector
                      class="col-span-5"
                      :list-options="listOptionsState?.prodi"
                      @cbox-select="selectProdi"
                    ></TailSelectSelector>
                  </div>
                </div>
                <div class="grid grid-cols-3 gap-3 mb-2">
                  <div class="col-span-1">
                    <label class="form-label">Tahun Ajaran</label>
                    <input
                      class="form-control"
                      :value="smt?.thn_ajaran?.nm_thn_ajaran"
                      readonly
                      disabled
                    />
                  </div>
                  <div class="col-span-1">
                    <label class="form-label">Semester</label>
                    <input
                      class="form-control"
                      :value="smt?.nm_smt"
                      readonly
                      disabled
                    />
                  </div>
                  <div class="col-span-1">
                    <label class="form-label">Status Kuliah</label>
                    <select v-model="fStatus" class="form-select w-full">
                      <option value="A">Aktif</option>
                      <option value="Z">Menunggu Pembayaran</option>
                      <option value="C">Cuti</option>
                      <option value="N">Non Aktif</option>
                      <option value="L">Lulus</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-span-12 xl:col-span-4 p-3">
                <label class="form-label">Angkatan</label>
                <select v-model="angkatan" class="form-select sm:mr-2">
                  <option
                    v-for="smtr in semester"
                    :key="smtr.id"
                    :value="smtr.id"
                  >
                    {{ smtr.value }}
                  </option>
                </select>

                <button
                  class="btn btn-success w-50 mr-5 mt-10"
                  :disabled="!smsSelected || !smtSelected"
                  @click="cariData"
                >
                  Cek Status
                </button>
              </div>
            </div>
          </div>

          <div class="intro-y box p-5 mt-5">
            <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
              <form
                id="tabulator-html-filter-form"
                class="xl:flex sm:mr-auto"
                @submit.prevent="onFilter"
              >
                <div class="sm:flex items-center sm:mr-4">
                  <select
                    id="tabulator-html-filter-field"
                    v-model="filter.field"
                    class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
                  >
                    <option value="npm">NPM</option>
                    <option value="nama">Nama</option>
                  </select>
                </div>

                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                  <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                    >Value</label
                  >
                  <input
                    id="tabulator-html-filter-value"
                    v-model="filter.value"
                    type="text"
                    class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
                    placeholder="Search..."
                  />
                </div>
                <div class="mt-2 xl:mt-0">
                  <button
                    id="tabulator-html-filter-go"
                    type="button"
                    class="btn btn-primary w-full sm:w-16"
                    @click="onFilter"
                  >
                    Go
                  </button>
                  <button
                    id="tabulator-html-filter-reset"
                    type="button"
                    class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                    @click="onResetFilter"
                  >
                    Reset
                  </button>
                </div>
              </form>
              <div class="flex mt-5 sm:mt-0">
                <button
                  class="btn btn-danger w-1/2 sm:w-auto mr-6"
                  @click="validasiUndurBayar = true"
                >
                  <CreditCardIcon class="w-4 h-4 mr-2" /> Validasi Pengunduran
                  Pembayaran
                </button>
                <button
                  id="tabulator-print"
                  class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
                  @click="onPrint"
                >
                  <PrinterIcon class="w-4 h-4 mr-2" /> Print
                </button>
                <div class="dropdown w-1/2 sm:w-auto">
                  <button
                    class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
                    aria-expanded="false"
                  >
                    <FileTextIcon class="w-4 h-4 mr-2" /> Export
                    <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                  </button>
                  <div class="dropdown-menu w-40">
                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                      <a
                        id="tabulator-export-xlsx"
                        href="javascript:"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                        @click="onExportXlsx"
                      >
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                      </a>
                      <a
                        id="tabulator-export-html"
                        href="javascript:"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                        @click="onExportHtml"
                      >
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export HTML
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="overflow-x-auto scrollbar-hidden">
              <Alert
                v-if="statusBlock.status != null"
                class="mt-2"
                :type="statusBlock.status ? 'alert-success' : 'alert-danger'"
                :dismissable="true"
                >{{ statusBlock.msg }}</Alert
              >
              <Alert
                v-if="statusValidasiUndur.status != null"
                class="mt-2"
                :type="
                  statusValidasiUndur.status ? 'alert-success' : 'alert-danger'
                "
                :dismissable="true"
                >{{ statusValidasiUndur.msg }}</Alert
              >
              <div
                id="tabulator"
                ref="tableRef"
                class="mt-5 table-report table-report--tabulator"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <ModalDialogPositive
        :id="'konfirm-modal'"
        :question="
          'Anda yakin ingin melakukan Validasi Buka KRS Mahasiswa ' +
          detail.mhs?.nm_pd +
          ' (' +
          detail.mhs?.nipd +
          ')?'
        "
      >
        <template #delete-button>
          <button
            type="button"
            class="btn btn-success w-24"
            @click="konfirmBukaKRS"
          >
            Ya
          </button>
        </template>
      </ModalDialogPositive>

      <ModalDialogPositive
        :id="'konfirm-block-modal'"
        :question="
          'Anda yakin ingin menutup Akses KRS Mahasiswa ' +
          detail.mhs?.nm_pd +
          ' (' +
          detail.mhs?.nipd +
          ')?'
        "
      >
        <template #delete-button>
          <button
            type="button"
            class="btn btn-success w-24"
            @click="konfirmBukaKRS"
          >
            Ya
          </button>
        </template>
      </ModalDialogPositive>
      <SlideOver
        :open="validasiUndurBayar"
        size="max-w-5xl"
        @close="validasiUndurBayar = false"
      >
        <template #title>Validasi Pengunduran Pembayaran</template>
        <div class="h-full" aria-hidden="true">
          <div class="grid grid-cols-12 gap-3">
            <div class="col-span-12">
              <TailSelect
                v-model="list_mhs"
                :options="{
                  search: true,
                  descriptions: true,
                  hideSelected: true,
                  hideDisabled: true,
                  classNames: 'w-full mb-3',
                  multiShowCount: false,
                  multiContainer: true,
                }"
                readonly="readonly"
                multiple
              >
                <option
                  v-for="(list, k) in model.list_mhs"
                  :key="k"
                  :value="list"
                >
                  {{ list }}
                </option>
              </TailSelect>
            </div>
            <div class="col-span-2">
              <label class="form-label">Tahun Akademik</label>
              <select v-model="validasi_id_smt" class="form-select sm:mr-2">
                <option
                  v-for="list in listOptionsState?.semester"
                  :key="list.id_smt"
                  :value="list.id_smt"
                >
                  {{ list.text }}
                </option>
              </select>
            </div>
            <div class="col-span-3">
              <label class="form-label">Jenis Permohonan</label>
              <select
                v-model="validasi_jenis_biaya"
                class="form-select sm:mr-2"
              >
                <option
                  v-for="list in jenisPermohonanList"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-span-2">
              <label class="form-label">Tgl Pengusulan</label>
              <input
                v-model="validasi_tgl_pengusulan"
                type="date"
                class="form-control"
              />
            </div>
            <div class="col-span-2">
              <label class="form-label">Tgl Rencana Bayar</label>
              <input
                v-model="validasi_tgl_rencana_bayar"
                type="date"
                class="form-control"
              />
            </div>
            <div class="col-span-3">
              <button
                :disabled="
                  validasi_tgl_pengusulan == '' ||
                  validasi_tgl_rencana_bayar == ''
                "
                class="btn btn-success mt-7"
                @click="simpanValidasiPengunduran"
              >
                Simpan
              </button>
            </div>
          </div>
          <Alert
            v-if="statusValidasiUndur.status != null"
            class="mt-2"
            :type="
              statusValidasiUndur.status ? 'alert-success' : 'alert-danger'
            "
            :dismissable="true"
            >{{ statusValidasiUndur.msg }}</Alert
          >
        </div>
      </SlideOver>
    </div>
  </MainLayout>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import xlsx from "xlsx";
import { useStore } from "vuex";
import feather from "feather-icons";
import { HTTPKEU } from "@/services/http";
import TailSelectSelector from "@/components/form/TailSelectSelector";
import Alert from "@/components/alerts/Alert";
import { option } from "@/utils/listoptions";
import MainLayout from "@/layouts/main/MainLayout";
import { tblTabulator } from "@/utils/tabulator";
import store from "@/store";
import ModalDialogPositive from "@/components/utility/ModalDialogPositive";
import SlideOver from "../../../layouts/main/SlideOver";

export default {
  name: "ValidasiPengisianKRS",
  components: {
    SlideOver,
    ModalDialogPositive,
    MainLayout,
    Alert,
    TailSelectSelector,
  },

  setup() {
    const tableRef = ref();
    const tabulator = ref();
    const idData = ref();
    const store = useStore();
    const filter = reactive({
      field: "npm",
      type: "like",
      value: "",
    });
    let panelOpen = ref(false);
    let panel = reactive({ panelOpen });
    let mhs = null;
    let detail = reactive({ mhs });
    let model = reactive({
      id_stat_mhs: null,
      id_reg_pd: null,
      id_smt: null,
      check_all: false,
      list_mhs: [],
    });
    let listMhs = ref();

    let id_jenis_biaya = null;

    const konfirmUbahStatus = (e, cell) => {
      model.id_stat_mhs = null;
      model.id_smt = null;
      model.id_reg_pd = null;
      if (cell.getData().id_stat_mhs === "Z") {
        model.id_stat_mhs = cell.getData().id_stat_mhs;
        model.id_reg_pd = cell.getData().id_reg_pd;
        model.id_smt = cell.getData().id_smt;
        detail.mhs = cell.getData();
        cash("#konfirm-modal").modal("show");
      } else if (cell.getData().id_stat_mhs === "A") {
        model.id_stat_mhs = cell.getData().id_stat_mhs;
        model.id_reg_pd = cell.getData().id_reg_pd;
        model.id_smt = cell.getData().id_smt;
        detail.mhs = cell.getData();
        cash("#konfirm-block-modal").modal("show");
      }
    };

    //panel = true;
    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        { formatter: "rownum", hozAlign: "center", width: 40 },
        {
          title: "Validasi",
          field: "nipd",
          hozAlign: "center",
          width: 40,
          headerSort: false,
          headerClick: function (e, column) {
            //console.log(listMhs);
            //e - the click event object
            //column - column component
            //model.check_all = false;
            if (model.check_all === false) {
              model.check_all = true;
              cash(".cek-validasi").prop("checked", true);
              model.list_mhs = column._column.cells.map((h) => {
                return h.getValue();
              });
              //console.log(model.id_reg_pd);
            } else {
              model.list_mhs = [];
              model.check_all = false;
              cash(".cek-validasi").prop("checked", false);
            }
            console.log(model.list_mhs);
          },
          formatter: (cell) => {
            const value = cell.getValue();
            if (value) {
              const a = cash(
                `<input type="checkbox" class="cek-validasi" id="${
                  "cv-" + cell.getValue()
                }" value="${cell.getValue()}">`
              );
              cash(a).on("click", function () {
                model.check_all = false;
                let st = cash("#cv-" + cell.getValue()).prop("checked");
                let cval = cash("#cv-" + cell.getValue()).val();
                if (st) {
                  model.list_mhs.push(cval);
                } else {
                  const index = model.list_mhs.indexOf(cval);
                  if (index > -1) {
                    model.list_mhs.splice(index, 1); // 2nd parameter means remove one item only
                  }
                }
                console.log(model.list_mhs);
              });
              return a[0];
            }
          },
        },
        // For HTML table
        {
          title: "Mahasiswa",
          field: "nipd",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
          formatter(cell) {
            return (
              "<div><div class='whitespace-normal font-medium'>" +
              cell.getData().nipd +
              "</div> " +
              "<div class='whitespace-normal text-xs'>" +
              cell.getData().nm_pd +
              "</div>" +
              "</div>"
            );
          },
        },
        {
          title: "Kelas Kuliah",
          field: "ket_kelompok_kelas",
          hozAlign: "center",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
        },
        {
          title: "Status Mahasiswa",
          field: "id_stat_mhs",
          hozAlign: "center",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
        },

        {
          title: "Keterangan",
          field: "pengajuan_keuangan",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            if (cell.getData().pengajuan_keuangan) {
              return (
                "<div>" +
                "<div class='whitespace-normal'>" +
                cell.getData().pengajuan_keuangan?.nama_pengajuan +
                "</div>" +
                "<div class='whitespace-normal text-xs'>Tgl Usulan : " +
                cell.getData().pengajuan_keuangan?.tgl_pengusulan +
                "</div>" +
                "</div>"
              );
            }
          },
        },

        {
          title: "",
          field: "id",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            if (cell.getData().id_stat_mhs == "Z") {
              const a = `<div class="flex lg:justify-center items-center">
                <btn class="flex btn btn-sm btn-success items-center">
                  <i data-feather="check-square" class="w-3 h-3 mr-1"></i> Boleh KRS
                </btn>
              </div>`;
              return `${a}`;
            } else if (cell.getData().id_stat_mhs == "A") {
              const a = `<div class="flex lg:justify-center items-center">
                <btn class="flex btn btn-sm btn-warning items-center">
                  <i data-feather="x-octagon" class="w-3 h-3 mr-1"></i> Block KRS
                </btn>
              </div>`;
              return `${a}`;
            }
            return "";
          },
          cellClick: konfirmUbahStatus,
        },
      ];
      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        "biaya/mhs_aktif_krs",
        columns,
        true,
        null,
        { jenis_biaya: id_jenis_biaya }
      );
    };
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };
    // On reset filter
    const onResetFilter = () => {
      filter.field = "npm";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };

    // Filter function
    const onFilterCustom = (field, type, value) => {
      tabulator.value.setFilter(field, type, value);
    };
    // Export
    const onExportCsv = () => {
      tabulator.value.download("csv", "data.csv");
    };
    const onExportJson = () => {
      tabulator.value.download("json", "data.json");
    };
    const onExportXlsx = () => {
      const win = window;
      win.XLSX = xlsx;
      tabulator.value.download("xlsx", "data.xlsx", {
        sheetName: "Products",
      });
    };
    const onExportHtml = () => {
      tabulator.value.download("html", "data.html", {
        style: true,
      });
    };
    // Print
    const onPrint = () => {
      tabulator.value.print();
    };
    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });
    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onFilterCustom,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      idData,
      panel,
      detail,
      model,
      listMhs,
    };
  },
  data() {
    return {
      smt: { a_periode_aktif: 0 },
      loading: false,
      angkatan: new Date().getFullYear(),
      sms: null,
      smsSelected: null,
      smtSelected: null,
      statusBlock: {
        msg: null,
        status: null,
      },
      statusValidasiUndur: {
        msg: null,
        status: null,
      },
      panelOpen: false,
      needreset: false,
      transaksiBank: null,
      allowValidasi: false,
      confirmValidasi: false,
      jenisPermohonanList: null,
      jenisPermohonanSelected: null,
      statistik: null,
      fStatus: "Z",
      validasiUndurBayar: false,
      list_mhs: [],
      validasi_id_smt: null,
      validasi_jenis_biaya: 20,
      validasi_tgl_pengusulan: "",
      validasi_tgl_rencana_bayar: "",
    };
  },

  computed: {
    semester() {
      let year = new Date().getFullYear();
      let years = [];
      years.push({ id: 0, value: "Semua" });
      for (let i = 2015; i <= year; i++) {
        years.push({ id: i, value: i });
      }
      return years;
    },
    listOptionsState() {
      return this.$store.state.main.listOptions;
    },
    tabulatorLoaded() {
      return this.$store.state.main.tabulatorDataLoaded;
    },
  },
  watch: {
    tabulatorLoaded: {
      handler: function (h) {
        if (h === true) {
          this.$nextTick(() => {
            store.commit("main/resetTabulatorDataLoaded");
          });

          if (this.needreset === true) {
            this.onResetFilter();
            this.needreset = false;
          }
        }
      },
      immediate: true,
    },
    /*validasijenis_biaya: {
      handler: function () {
        console.log(this.jenis_biaya);
        //this.setJenisPermohonanSelected();
      },
      immediate: true,
    },*/
    fStatus: {
      handler: function (h) {
        console.log(h);
      },
      immediate: true,
    },
    model: {
      handler: function (h) {
        this.list_mhs = h.list_mhs;
      },
      immediate: true,
      deep: true,
    },
  },
  beforeMount() {
    option.fetchSemester();
    option.fetchProdi();
    this.getBankList();
    this.getProdukList();
    this.getSubcomList();
    this.getJenisPermohonanList();
  },

  methods: {
    getJenisPermohonanList() {
      HTTPKEU.get("list/jenispermohonan")
        .then((res) => {
          this.jenisPermohonanList = res.data.filter(
            (f) => f?.u_pembiayaan === 0
          );
        })
        .finally(() => {});
    },
    setJenisPermohonanSelected() {
      //let h = parseInt(this.jenis_biaya);
    },
    validasiTransaksi() {
      this.confirmValidasi = true;
      this.allowValidasi = true;
    },
    selectProdi(curr, id) {
      this.smsSelected = id;
      this.sms = curr[0];
    },
    selectSemester(curr, id) {
      this.smtSelected = id;
      this.smt = curr[0];
      this.validasi_id_smt = id;
    },
    getSubcomList() {
      HTTPKEU.get("list/subcomp").then((res) => {
        this.subcomps = res.data;
      });
    },
    getProdukList() {
      HTTPKEU.get("list/produk").then((res) => {
        this.produks = res.data;
      });
    },
    getBankList() {
      HTTPKEU.get("list/bank").then((res) => {
        this.banks = res.data;
      });
    },

    konfirmBukaKRS() {
      this.statusBlock.status = null;
      this.statusBlock.msg = null;
      let url = this.model.id_stat_mhs === "A" ? "block_krs" : "buka_krs";
      HTTPKEU.post(url, { ...this.model })
        .then((res) => {
          if (res.status === 200) {
            this.statusBlock.status = true;
            this.statusBlock.msg = res.data.message;
          } else {
            this.statusBlock.status = false;
            this.statusBlock.msg = res.data.message;
          }
        })
        .catch((e) => {
          this.statusBlock.status = false;
          this.statusBlock.msg = e.response?.data?.message;
        })
        .finally(() => {
          this.cariData();
        });

      cash("#konfirm-modal").modal("hide");
      cash("#konfirm-block-modal").modal("hide");
      //this.biayatagihanid = null;
    },
    closePanel(close) {
      this.panelOpen = close;
      this.panel.panelOpen = close;

      //Validation
      this.transaksiBank = null;
      this.allowValidasi = false;
      this.confirmValidasi = false;
      this.statusValidasi.status = null;
      this.statusValidasi.msg = null;
    },
    cariData() {
      this.tabulator.setData(
        process.env.VUE_APP_KEUANGAN + "biaya/mhs_aktif_krs",
        {
          filter: {
            id_smt: this.smtSelected,
            id_sms: this.smsSelected,
            angkatan: this.angkatan,
          },
          status: this.fStatus,
        },
        tblTabulator.ajaxConfig()
      );
    },
    simpanValidasiPengunduran() {
      this.statusValidasiUndur.status = null;
      this.statusValidasiUndur.msg = null;
      if (
        this.validasi_tgl_pengusulan != "" &&
        this.validasi_tgl_rencana_bayar != ""
      ) {
        HTTPKEU.post("permohonankeuangan/validasi", {
          data: this.model.list_mhs.map((m) => {
            return { nipd: m };
          }),
          tgl_pengusulan: this.validasi_tgl_pengusulan,
          tgl_rencana_bayar: this.validasi_tgl_rencana_bayar,
          id_smt: this.validasi_id_smt,
          id_jenis_biaya: this.validasi_jenis_biaya,
        })
          .then((res) => {
            if (res.status === 200) {
              this.statusValidasiUndur.status = true;
              this.statusValidasiUndur.msg = res.data.message;
            } else {
              this.statusValidasiUndur.status = false;
              this.statusValidasiUndur.msg = res.data.message;
            }
          })
          .catch((e) => {
            this.statusValidasiUndur.status = false;
            this.statusValidasiUndur.msg = e.response?.data?.message;
          })
          .finally(() => {
            this.validasiUndurBayar = false;
            this.cariData();
          });
      }
    },
  },
};
</script>

<style scoped></style>
