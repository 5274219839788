<template>
  <div
    class="alert show flex items-center mb-2"
    :class="alertclass"
    role="alert"
  >
    <CheckCircleIcon v-if="type === 'alert-success'" class="w-6 h-6 mr-2" />
    <AlertOctagonIcon v-else class="w-6 h-6 mr-2" />
    <main>
      <slot></slot>
    </main>
    <button
      v-if="dismissable"
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
      @click="closeAndClear"
    >
      <XIcon class="w-4 h-4" />
    </button>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    type: {
      type: String,
      default: "alert-warning",
    },
    dismissable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    alertclass() {
      return this.dismissable === true
        ? this.type + " alert-dismissible"
        : this.type;
    },
  },
  methods: {
    closeAndClear() {
      this.$emit("closeAndClear", null);
      console.log("dismiss");
    },
  },
};
</script>
